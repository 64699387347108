import { Trans, useTranslation } from 'react-i18next';
import type { FormEventHandler } from 'react';
import { useCallback } from 'react';
import { toast } from 'sonner';

import TextField from '~/core/ui/TextField';
import Button from '~/core/ui/Button';
import If from '~/core/ui/If';
import Alert from '~/core/ui/Alert';
import useSignInWithOtp from '~/core/hooks/use-sign-in-with-otp';

import configuration from '~/configuration';
import { Checkbox } from '~/core/ui/Checkbox';

const EmailLinkAuth: React.FC<{
  inviteCode?: string;
  mustAgreeToTerms?: boolean;
}> = ({ inviteCode, mustAgreeToTerms = true }) => {
  const { t } = useTranslation();
  const signInWithOtpMutation = useSignInWithOtp();

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async (event) => {
      event.preventDefault();

      const target = event.currentTarget;
      const data = new FormData(target);
      const email = data.get('email') as string;

      const origin = window.location.origin;
      const queryParams = inviteCode ? `?inviteCode=${inviteCode}` : '';

      const emailRedirectTo = [
        origin,
        configuration.paths.callback,
        queryParams,
      ].join('');

      const promise = signInWithOtpMutation.mutateAsync({
        email,
        options: {
          emailRedirectTo,
        },
      });

      toast.promise(promise, {
        loading: t('auth:sendingEmailLink'),
        success: t(`auth:sendLinkSuccessToast`),
        error: t(`auth:errors.link`),
      });
    },
    [inviteCode, signInWithOtpMutation, t],
  );

  if (signInWithOtpMutation.isSuccess) {
    return (
      <Alert type={'success'}>
        <Trans i18nKey={'auth:sendLinkSuccess'} />
      </Alert>
    );
  }

  return (
    <form className={'w-full'} onSubmit={onSubmit}>
      <div className={'flex flex-col space-y-4'}>
        <TextField>
          <TextField.Label>
            <Trans i18nKey={'common:emailAddress'} />

            <TextField.Input
              data-cy={'email-input'}
              required
              type="email"
              placeholder={'your@email.com'}
              name={'email'}
            />
          </TextField.Label>
        </TextField>

        <If condition={signInWithOtpMutation.error}>
          <Alert type={'error'}>
            <Trans i18nKey={'auth:errors.link'} />
          </Alert>
        </If>

        <If condition={mustAgreeToTerms}>
          <div className="flex items-start space-x-2 py-2">
            <Checkbox id="terms" required />
            <label
              htmlFor="terms"
              className="text-sm text-gray-700 mt-[-2px]"
              dangerouslySetInnerHTML={{ __html: t(`auth:signUpTerms`) }}
            />
          </div>
        </If>

        <Button loading={signInWithOtpMutation.isPending}>
          <If
            condition={signInWithOtpMutation.isPending}
            fallback={<Trans i18nKey={'auth:sendEmailLink'} />}
          >
            <Trans i18nKey={'auth:sendingEmailLink'} />
          </If>
        </Button>
      </div>
    </form>
  );
};

export default EmailLinkAuth;
