import { useMutation } from '@tanstack/react-query';
import type {
  AuthError,
  SignInWithPasswordlessCredentials,
} from '@supabase/supabase-js';

import useSupabase from '~/core/hooks/use-supabase';
import configuration from '~/configuration';

/**
 * @name useSignInWithOtp
 */
function useSignInWithOtp() {
  const client = useSupabase();

  const mutationFn = async (credentials: SignInWithPasswordlessCredentials) => {
    const response = await client.auth.signInWithOtp(credentials);

    if (response.error) {
      if (shouldIgnoreError(response.error)) {
        console.warn(
          `Ignoring error during development: ${response.error.message}`,
        );

        return {};
      }

      throw response.error;
    }

    return response.data;
  };

  return useMutation({
    mutationFn,
  });
}

export default useSignInWithOtp;

function shouldIgnoreError(error: AuthError) {
  return !configuration.production && isSmsProviderNotSetupError(error);
}

function isSmsProviderNotSetupError(error: AuthError) {
  return (
    error.message === `Error sending sms: sms Provider  could not be found`
  );
}
