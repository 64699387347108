import { useMutation } from '@tanstack/react-query';
import useSupabase from '~/core/hooks/use-supabase';
import type { VerifyOtpParams } from '@supabase/auth-js';

/**
 * @name useVerifyOtp
 * @description Verify the OTP sent to the user's phone number
 */
function useVerifyOtp() {
  const client = useSupabase();

  const mutationFn = async (params: VerifyOtpParams) => {
    const { data, error } = await client.auth.verifyOtp({
      ...params,
    });

    if (error) {
      throw error;
    }

    return data;
  };

  return useMutation({
    mutationFn,
  });
}

export default useVerifyOtp;
